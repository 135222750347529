import { FlowProgress, FlowType, ModuleType, ProductCode } from './types';

export const productCodeSeqList = Object.values(ProductCode);
export const flowProgressSeqList = Object.values(FlowProgress);

export const ModuleToFlow = {
  [ModuleType.Welcome]: FlowType.Quote,
  [ModuleType.Home]: FlowType.Quote,
  [ModuleType.Auto]: FlowType.Quote,
  [ModuleType.Cycle]: FlowType.Quote,
  [ModuleType.YourDetails]: FlowType.Quote,
  [ModuleType.Quote]: FlowType.Quote,
  [ModuleType.AgentsAndLenders]: FlowType.Bind,
  [ModuleType.FinalDetails]: FlowType.Bind,
  [ModuleType.BindYourPolicy]: FlowType.Bind,
  [ModuleType.Payment]: FlowType.Bind,
  [ModuleType.PolicyConfirmation]: FlowType.Bind,
};

export const ModuleToFlowProgressBoundary = {
  [ModuleType.Welcome]: {
    startInclusive: FlowProgress.Started,
    endExclusive: FlowProgress.PNI,
  },
  [ModuleType.Home]: {
    startInclusive: FlowProgress.PNI,
    endExclusive: FlowProgress.App,
  },
  [ModuleType.Auto]: {
    startInclusive: FlowProgress.PNI,
    endExclusive: FlowProgress.App,
  },
  [ModuleType.Cycle]: {
    startInclusive: FlowProgress.PNI,
    endExclusive: FlowProgress.Quoted,
  },
  [ModuleType.YourDetails]: {
    startInclusive: FlowProgress.Quote,
    endExclusive: FlowProgress.App,
  },
  [ModuleType.Quote]: {
    startInclusive: FlowProgress.Quote,
    endExclusive: FlowProgress.App,
  },
  [ModuleType.AgentsAndLenders]: {
    startInclusive: FlowProgress.App,
    endExclusive: FlowProgress.Signed,
  },
  [ModuleType.FinalDetails]: {
    startInclusive: FlowProgress.App,
    endExclusive: FlowProgress.Signed,
  },
  [ModuleType.BindYourPolicy]: {
    startInclusive: FlowProgress.App,
    endExclusive: FlowProgress.Signed,
  },
  [ModuleType.Payment]: {
    startInclusive: FlowProgress.Signed,
    endExclusive: FlowProgress.Bound,
  },
  [ModuleType.PolicyConfirmation]: {
    startInclusive: FlowProgress.Signed,
    endExclusive: FlowProgress.Complete,
  },
};

export const ProductToModule = {
  [ProductCode.Home as string]: ModuleType.Home,
  [ProductCode.Renters as string]: null,
  [ProductCode.Auto as string]: ModuleType.Auto,
  [ProductCode.Cycle as string]: ModuleType.Cycle,
};
