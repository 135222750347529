/* eslint-disable no-console */
import type { Location } from 'history';
import isEmpty from 'lodash/isEmpty';
import type { FlowProgressStatus, Module, Page } from 'piral-core';
import {
  flowProgressSeqList,
  productCodeSeqList,
  ProductToModule,
} from './maps';
import {
  FlowProgress,
  FlowProgressBoundary,
  ModuleType,
  ProductCode,
} from './types';

interface ProductStatus {
  code: ProductCode;
  flowProgress: FlowProgress;
}

const createProductStatusList = (
  currentFlowProgressStatus: FlowProgressStatus | null,
): ProductStatus[] => {
  const { timestamp, ...productStatuses } =
    currentFlowProgressStatus || ({} as FlowProgressStatus);
  return Object.entries(productStatuses)
    .sort(([codeA, flowProgressA], [codeB, flowProgressB]) => {
      const result =
        flowProgressSeqList.indexOf(flowProgressA as FlowProgress) -
        flowProgressSeqList.indexOf(flowProgressB as FlowProgress);
      if (result === 0) {
        return (
          productCodeSeqList.indexOf(codeA as ProductCode) -
          productCodeSeqList.indexOf(codeB as ProductCode)
        );
      }
      return result;
    })
    .map(([code, flowProgress]) => ({ code, flowProgress }) as ProductStatus);
};

const canNavigate = (
  productStatus: ProductStatus,
  flowProgressBoundary: FlowProgressBoundary,
  pageProductCodes?: ProductCode[],
) => {
  const flowProgressOrdinal = flowProgressSeqList.indexOf(
    productStatus.flowProgress,
  );
  const { startInclusive, endExclusive } = flowProgressBoundary;
  const startProgressOrdinal = flowProgressSeqList.indexOf(startInclusive);
  const endProgressOrdinal = flowProgressSeqList.indexOf(endExclusive);

  const isNavigable =
    startProgressOrdinal <= flowProgressOrdinal &&
    endProgressOrdinal > flowProgressOrdinal;

  if (pageProductCodes) {
    return isNavigable && pageProductCodes.includes(productStatus.code);
  }
  return isNavigable;
};

export const createCanNavigate = (
  currentFlowProgressStatus: FlowProgressStatus | null,
) => {
  const productStatusList = createProductStatusList(currentFlowProgressStatus);

  return (page: Page) => {
    if (isEmpty(productStatusList)) {
      return true;
    }

    const { flowProgressBoundary, productCodes } = page;

    if (isEmpty(productCodes)) {
      const firstProductStatus = productStatusList[0];
      const lastProductStatus = productStatusList[productStatusList.length - 1];

      if (
        flowProgressBoundary?.startInclusive === FlowProgress.App &&
        flowProgressBoundary?.endExclusive === FlowProgress.Signed
      ) {
        return canNavigate(lastProductStatus, flowProgressBoundary!);
      }

      return canNavigate(firstProductStatus, flowProgressBoundary!);
    }

    return productCodes!.every((productCode) => {
      const productStatus = productStatusList.find(
        ({ code }) => code === productCode,
      );
      if (productStatus) {
        return canNavigate(productStatus, flowProgressBoundary!);
      }
      return true;
    });
  };
};

export const createCanResume = (
  currentFlowProgressStatus: FlowProgressStatus | null,
) => {
  const productStatusList = createProductStatusList(currentFlowProgressStatus);

  return (page: Page, module: Module) => {
    if (isEmpty(productStatusList)) {
      return true;
    }

    const firstStatus = productStatusList[0];
    const lastStatus = productStatusList[productStatusList.length - 1];
    const { flowProgressBoundary, productCodes = [] } = page;
    // If firstStatus product doesn't have a module then don't use page productCodes instance.
    // This will allow the first non-product page to render that's within the flow boundary.
    const productQuestionPageCodes = ProductToModule[firstStatus.code]
      ? productCodes
      : undefined;

    switch (firstStatus.flowProgress) {
      case FlowProgress.Started:
        // Go to first start page
        return canNavigate(firstStatus, flowProgressBoundary!);
      case FlowProgress.PNI:
        if (lastStatus.flowProgress === FlowProgress.PNI) {
          // Go to first page that requires PNI
          return canNavigate(lastStatus, flowProgressBoundary!);
        }
        // Go to first product question page
        return canNavigate(
          firstStatus,
          flowProgressBoundary!,
          isEmpty(productCodes) ? productQuestionPageCodes : productCodeSeqList,
        );
      case FlowProgress.Quote:
        // Go to first product question page
        return canNavigate(
          firstStatus,
          flowProgressBoundary!,
          isEmpty(productCodes) ? productQuestionPageCodes : productCodeSeqList,
        );
      case FlowProgress.Quoted:
        // Go to first quote module page
        return module.type === ModuleType.Quote;
      case FlowProgress.App:
        if (lastStatus.flowProgress === FlowProgress.App) {
          // Go to first page that requires App
          return canNavigate(lastStatus, flowProgressBoundary!);
        }
        // Go to first product that needs to sign
        return (
          module.type === ModuleType.BindYourPolicy &&
          canNavigate(firstStatus, flowProgressBoundary!, productCodes)
        );
      case FlowProgress.Signed:
        // Go to first page that requires Signed
        return canNavigate(firstStatus, flowProgressBoundary!);
      case FlowProgress.Bound:
        // Go to first completion page
        return module.type === ModuleType.PolicyConfirmation;
      default:
        // If no status then go to first start page
        return flowProgressBoundary?.startInclusive === FlowProgress.Started;
    }
  };
};

/**
 * Sets the tid and CrossSellValue values if the user is coming from MyAccount
 * @param location the URL values
 * @param routeParams parameters to be added to the URL
 * @returns routeParams: either updated with the MyAccount values or the original
 */
export const getMyAccountValues = (
  location: Location,
  routeParams: URLSearchParams,
): URLSearchParams => {
  // MyAccount Cross Sell Tracking Tag/Cross Sell Value values
  const urlSearchParams = new URLSearchParams(location.search);
  const tid = urlSearchParams.get('tid');
  const crossSellValue = urlSearchParams.get('CrossSellValue');
  // Since the tid and CrossSellValue appear in the URL initially when the user is coming over to DSP, looking for those to know if we should append
  if (tid && crossSellValue) {
    routeParams.set('tid', 'CrossSellAutoDSPWeb');
    routeParams.set('CrossSellValue', 'true');
  }
  return routeParams;
};

export const getRefinedRoute =
  (
    route: string,
    packageId?: string,
    otherParams?: URLSearchParams,
    fromPrecedingPage: boolean = false,
  ) =>
  (location: Location): Partial<Location> => {
    // prep route
    const routeParts = route.split('?');
    const routeParams = new URLSearchParams(
      routeParts.length > 1 ? routeParts[1] : undefined,
    );

    // finalize routeParams
    /// because URLSearchParams interface is based on key/value string pairs (set or append)
    /// it's necessary to delete all keys that otherParams is overriding first
    /// then append back the keys to support their new value(s)
    otherParams?.forEach((_, key) => {
      routeParams.delete(key);
    });
    otherParams?.forEach((value, key) => {
      routeParams.append(key, value);
    });

    // manage packageId
    const origPackageId = new URLSearchParams(location.search).get('packageId');
    const finalPackageId = origPackageId || packageId;
    if (finalPackageId) {
      routeParams.set('packageId', finalPackageId);
    } else {
      routeParams.delete('packageId');
    }

    // MyAccount URL values
    const updatedRouteParams = getMyAccountValues(location, routeParams);

    const state = { fromRoute: location.pathname, fromPrecedingPage };
    return {
      pathname: routeParts[0],
      search: `?${updatedRouteParams.toString()}`,
      state,
    };
  };

export const findNextPageLabel = (
  modules: Module[],
  location: Location,
  goBack?: boolean,
): string => {
  let currentPageFound = false;
  let nextPageLabel = '';

  (goBack ? modules.reverse() : modules).forEach((module) => {
    if (!nextPageLabel) {
      const pages = [...module.pages];
      (goBack ? pages.reverse() : pages)
        .filter((page) => page.enabled && !page.hidden)
        .forEach((page) => {
          if (page.route === location.pathname) {
            currentPageFound = true;
          } else if (currentPageFound) {
            currentPageFound = false;
            nextPageLabel = page.actionLabel!;
          }
        });
    }
  });

  return nextPageLabel;
};
