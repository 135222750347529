import ThemeProvider from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';
import { AdapterDayjs as DateAdapter } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type { ComponentsState, ErrorComponentsState } from 'piral-core';
import { CookiesProvider } from 'react-cookie';
import HardStopPage from './HardStop';
import Layout from './Layout';
import LoadingSpinner from './LoadingSpinner';

export const errors: Partial<ErrorComponentsState> = {
  not_found: () => <HardStopPage />,
  page: () => <HardStopPage />,
  unknown: () => <HardStopPage />,
  loading: () => <HardStopPage />,
  extension: () => <HardStopPage />,
};
export const layout: Partial<ComponentsState> = {
  ErrorInfo: () => <HardStopPage />,
  Layout: ({ children }) => {
    return (
      <ThemeProvider classNamePrefix="application" useDefaultTheme>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <CookiesProvider>
            <Layout>{children}</Layout>
          </CookiesProvider>
        </LocalizationProvider>
      </ThemeProvider>
    );
  },
  LoadingIndicator: () => (
    <ThemeProvider classNamePrefix="application" useDefaultTheme>
      <LoadingSpinner isLoading />
    </ThemeProvider>
  ),
};
