import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ThemeProvider from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';
import { v4 as uuidv4 } from 'uuid';
import { createInstance, Piral, SetErrors, SetLayout } from 'piral-core';
import { createRoot } from 'react-dom/client';
import createAppContextAPI from './app/ContextAPI';
import HardStopPage from './layout/HardStop';
import type { ResourceConfig } from './app/types';
import { errors, layout } from './layout';

// Below global variable is used to define domain to generate aws waf token.
// @ts-ignore
window.awsWafCookieDomainList = ['.amfam.com'];

declare let CONFIG: ResourceConfig;

try {
  const PILET_ORDER = ['customer', 'property', 'auto', 'quote', 'bind'];

  const instance = createInstance({
    plugins: [createAppContextAPI()],
    async requestPilets() {
      const { baseURL, headers } = CONFIG.uiFeedAPI;
      const fetchResponse = await fetch(baseURL, {
        headers: {
          ...headers,
          'dsp-client-request-id': uuidv4(),
        },
      });
      const jsonResponse = await fetchResponse.json();

      const items = [...(jsonResponse.items || [])];
      items.sort(
        (itemA, itemB) =>
          PILET_ORDER.findIndex((value) => itemA.name.includes(value)) -
          PILET_ORDER.findIndex((value) => itemB.name.includes(value)),
      );
      return items;
    },
  });

  // React 17 rendering
  // render(
  // React 18 rendering
  createRoot(document.getElementById('app')!).render(
    <Piral instance={instance}>
      <SetLayout layout={layout} />
      <SetErrors errors={errors} />
    </Piral>,
    // React 17 rendering
    // document.querySelector('#app'),
  );
} catch (e) {
  console.warn(e);
  // HardStopPage;
  createRoot(document.getElementById('app')!).render(
    <ThemeProvider classNamePrefix="application" useDefaultTheme>
      <HardStopPage />
    </ThemeProvider>,
  );
}
