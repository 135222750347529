import { Theme, useMediaQuery } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useCallCenterBoxStartPosition } from '../../app/ContextAPI';

/**
 * Hook to manage the scrolling of the right panel content
 * @param setShouldFix useState to set if the content should be fixed
 * @param customTheme custom theme being used
 * @returns rootOverride that is used for the call center box style
 */
export const useManageScrolling = (setShouldFix, customTheme) => {
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setShouldFix(window.scrollY > customTheme.sidePanel.scrollPoint);
    });
  }, [customTheme.sidePanel.scrollPoint, setShouldFix]);

  const callCenterBoxStartPosition = useCallCenterBoxStartPosition();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));

  // If it's desktop and there's a callCenterBoxStartPosition, set the top attribute to that start position value
  const rootOverride = useMemo(
    () =>
      isDesktop && callCenterBoxStartPosition
        ? {
            top: callCenterBoxStartPosition,
          }
        : undefined,
    [callCenterBoxStartPosition, isDesktop],
  );

  return useMemo(() => {
    return rootOverride;
  }, [rootOverride]);
};
