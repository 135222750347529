import type { Module } from 'piral-core';
import { FeatureManagement, FlowType, ModuleType } from './types';

const createModule = (
  type: ModuleType,
  label: string,
  featureManagement?: FeatureManagement,
): Module => ({
  type,
  label,
  pages: [],
  routes: [],
  routesWithoutHidden: [],
  active: false,
  featureManagement,
});

export default {
  quoteFlow: {
    [ModuleType.Welcome]: createModule(ModuleType.Welcome, 'Welcome'),
    [ModuleType.Home]: createModule(ModuleType.Home, 'Home'),
    [ModuleType.Auto]: createModule(ModuleType.Auto, 'Auto'),
    [ModuleType.Cycle]: createModule(ModuleType.Cycle, 'Motorcycle'),
    [ModuleType.YourDetails]: createModule(
      ModuleType.YourDetails,
      'Your details',
    ),
    [ModuleType.Quote]: createModule(ModuleType.Quote, 'Quote'),
  },
  bindFlow: {
    [ModuleType.AgentsAndLenders]: createModule(
      ModuleType.AgentsAndLenders,
      'Agents and lenders',
    ),
    [ModuleType.FinalDetails]: createModule(
      ModuleType.FinalDetails,
      'Final details',
    ),
    [ModuleType.BindYourPolicy]: createModule(
      ModuleType.BindYourPolicy,
      'Bind your policy',
    ),
    [ModuleType.Payment]: createModule(ModuleType.Payment, 'Payment'),
    [ModuleType.PolicyConfirmation]: createModule(
      ModuleType.PolicyConfirmation,
      'Policy confirmation',
    ),
  },
  currentFlow: FlowType.Quote,
  currentFlowProgressStatus: null,
};
