import {
  ProducerData,
  googleAnalyticsLogger,
} from '@amfament/digitalsales-lib-common-base/coreService';
import { isEqual, merge } from '@amfament/digitalsales-lib-coreuicomps/utils';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { GlobalStateContext, ProductQuoteId, withKey } from 'piral-core';

export const addAnalyticData = (
  ctx: GlobalStateContext,
  data: Record<string, any>,
): void =>
  ctx.dispatch((state) => {
    const analyticData = cloneDeep(state.digitalsales.analyticData);
    let newAnalyticData = {};

    if (data.dataSent) {
      newAnalyticData = merge(analyticData, data);
    } else {
      const { dataSent } = analyticData;
      let updatedDataSent = dataSent ? [...dataSent] : [];

      const newData = Object.fromEntries(
        Object.entries(data).map(([attr, newValue]) => {
          const origValue = analyticData[attr];
          if (
            origValue &&
            isEqual({ [attr]: origValue }, { [attr]: newValue })
          ) {
            return [attr, origValue];
          }
          updatedDataSent = updatedDataSent.filter(
            (dataAttr) => dataAttr !== attr,
          );
          return [attr, newValue];
        }),
      );

      newAnalyticData = merge(analyticData, {
        ...newData,
        dataSent: updatedDataSent,
      });
    }
    googleAnalyticsLogger(newAnalyticData);
    return {
      ...state,
      digitalsales: withKey(
        state.digitalsales,
        'analyticData',
        newAnalyticData,
      ),
    };
  });
export const getAnalyticData = (ctx: GlobalStateContext): Record<string, any> =>
  ctx.readState((state) => state.digitalsales.analyticData);

export const configureCallCenterBox = (
  ctx: GlobalStateContext,
  display: boolean,
  startPosition?: string,
  lock?: boolean,
): void =>
  ctx.dispatch((state) => ({
    ...state,
    digitalsales: withKey(
      withKey(
        withKey(state.digitalsales, 'displayCallCenterBox', !!display),
        'callCenterBoxStartPosition',
        startPosition || '',
      ),
      'lockCallCenterBox',
      !!lock,
    ),
  }));

export const configureProgressStepper = (
  ctx: GlobalStateContext,
  display: boolean,
  lock?: boolean,
): void =>
  ctx.dispatch((state) => ({
    ...state,
    digitalsales: withKey(
      withKey(state.digitalsales, 'displayProgressStepper', !!display),
      'lockProgressStepper',
      !!lock,
    ),
  }));

export const configureFullPageDisplay = (
  ctx: GlobalStateContext,
  fullPageDisplay: boolean,
): void =>
  ctx.dispatch((state) => ({
    ...state,
    digitalsales: withKey(
      state.digitalsales,
      'fullPageDisplay',
      fullPageDisplay,
    ),
  }));

/**
 * Configured the rather or not the background gradient displays on a page
 * @param ctx global context api from piral core
 * @param pageGradientDisplay rather or not to display the gradient background
 * @returns void
 */
export const configureGradientPageDisplay = (
  ctx: GlobalStateContext,
  pageGradientDisplay: boolean,
): void =>
  ctx.dispatch((state) => ({
    ...state,
    digitalsales: withKey(
      state.digitalsales,
      'pageGradientDisplay',
      pageGradientDisplay,
    ),
  }));

export const displaySaveForLater = (
  ctx: GlobalStateContext,
  indicator: boolean,
): void =>
  ctx.dispatch((state) => ({
    ...state,
    digitalsales: withKey(
      state.digitalsales,
      'displaySaveForLater',
      !!indicator,
    ),
  }));

export const setYourAgentData = (
  ctx: GlobalStateContext,
  agent: ProducerData | undefined,
): void =>
  ctx.dispatch((state) => ({
    ...state,
    digitalsales: withKey(state.digitalsales, 'yourAgentData', agent),
  }));

export const displayCreditBasedScoringLink = (
  ctx: GlobalStateContext,
  indicator: boolean,
): void =>
  ctx.dispatch((state) => ({
    ...state,
    digitalsales: withKey(
      state.digitalsales,
      'displayCreditBasedScoringLink',
      !!indicator,
    ),
  }));

export const displayHeaderFooter = (
  ctx: GlobalStateContext,
  indicator: boolean,
): void =>
  ctx.dispatch((state) => ({
    ...state,
    digitalsales: withKey(
      state.digitalsales,
      'displayHeaderFooter',
      !!indicator,
    ),
  }));

export const getResourceData = (
  ctx: GlobalStateContext,
  key: string,
  defaultData?: any,
): any => {
  const data = ctx.readState((state) => state.digitalsales.resourceData[key]);
  return isEmpty(data) ? defaultData : data;
};
export const setResourceData = (
  ctx: GlobalStateContext,
  key: string,
  data: any,
): void =>
  ctx.dispatch((state) => {
    const resourceData = {
      ...state.digitalsales.resourceData,
      [key]: data,
    };
    return {
      ...state,
      digitalsales: withKey(state.digitalsales, 'resourceData', resourceData),
    };
  });

export const setHeaderTitle = (
  ctx: GlobalStateContext,
  title: React.ReactNode,
): void =>
  ctx.dispatch((state) => ({
    ...state,
    digitalsales: withKey(state.digitalsales, 'headerTitle', title),
  }));

export const setLoadingState = (
  ctx: GlobalStateContext,
  processName: string,
  isLoading: boolean,
  message: React.ReactNode,
): void =>
  ctx.dispatch((state) => {
    let { loadingState } = state.digitalsales;
    if (isLoading) {
      if (!loadingState.some((p) => p.processName === processName)) {
        loadingState = [...loadingState, { processName, message }];
      }
    } else {
      loadingState = loadingState.filter((p) => p.processName !== processName);
    }
    return {
      ...state,
      digitalsales: withKey(state.digitalsales, 'loadingState', loadingState),
    };
  });

export const setProductQuoteIds = (
  ctx: GlobalStateContext,
  productQuoteIds: ProductQuoteId[],
): void =>
  ctx.dispatch((state) => ({
    ...state,
    digitalsales: withKey(
      state.digitalsales,
      'productQuoteIds',
      productQuoteIds,
    ),
  }));
