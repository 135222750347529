/* eslint-disable no-param-reassign */
import type {
  FeatureManagement,
  FeatureUserInfo,
} from '@amfament/digitalsales-lib-common-base/coreService';
import type { FeatureEntry } from '@amfament/digitalsales-lib-common-base/featureService';
import { isEqual } from '@amfament/digitalsales-lib-coreuicomps/utils';
import type { History } from 'history';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import {
  Flow,
  FlowProgressStatus,
  GlobalState,
  GlobalStateContext,
  Module,
  Page,
  PageConfig,
  PageEnabler,
  PageEnablerMap,
  withKey,
} from 'piral-core';
import {
  flowProgressSeqList,
  ModuleToFlow,
  ModuleToFlowProgressBoundary,
  productCodeSeqList,
} from './maps';
import { FlowProgress, FlowType, ModuleType, ProductCode } from './types';

const finalizeModule = (module: Module) => {
  const enabledPages = module.pages.filter(
    (page) => page.active && page.enabled,
  );
  module.routes = enabledPages.map((page) => page.route);
  module.routesWithoutHidden = enabledPages
    .filter((page) => !page.hidden)
    .map((page) => page.route);
  module.active = enabledPages.length > 0;
};

export const configureModule = (
  ctx: GlobalStateContext,
  type: ModuleType,
  pages: PageConfig[],
  productCodes?: ProductCode[],
): void =>
  ctx.dispatch((state) => {
    const flowName = ModuleToFlow[type];
    const flow = cloneDeep(state.digitalsales[flowName]);
    const module = flow[type];

    const newPages: Page[] = pages.map((pageConfig) => {
      const finalProductCodes = pageConfig.productCodes || productCodes;
      let pageOrdinal = productCodeSeqList.findIndex((productCode) =>
        finalProductCodes?.includes(productCode as ProductCode),
      );
      if (pageOrdinal === -1 && pageConfig.followProductType) {
        pageOrdinal = 30;
      }

      const flowProgressBoundary =
        pageConfig.flowProgressBoundary || ModuleToFlowProgressBoundary[type];
      const actionLabel = pageConfig.actionLabel || pageConfig.label;
      const featureManagement =
        pageConfig.featureManagement || module.featureManagement;

      return {
        ...pageConfig,
        productCodes: (finalProductCodes || []).sort() as ProductCode[],
        pageOrdinal,
        flowProgressBoundary,
        actionLabel,
        active: false,
        enabled: false,
        featureManagement,
      };
    });

    const finalPages = uniqBy([...module.pages, ...newPages], 'route');
    module.pages = sortBy(finalPages, 'pageOrdinal');
    finalizeModule(module);

    return {
      ...state,
      digitalsales: withKey(state.digitalsales, flowName, flow),
    };
  });

export const setFlowState = (
  ctx: GlobalStateContext,
  newFlowProgressStatus: FlowProgressStatus,
  history: History,
): void => {
  if (newFlowProgressStatus) {
    ctx.dispatch((state) => {
      let finalFlowProgressStatus = newFlowProgressStatus;

      const { currentFlowProgressStatus } = state.digitalsales;
      if (currentFlowProgressStatus) {
        // flowProgress always advances so should never be set back.
        // pilet jumping requires the target pilet to update it's state
        // which may be delayed for a brief moment until the answers api call returns.
        // This condition protects the currentFlowProgress from being set back due to the delay.
        if (
          currentFlowProgressStatus.timestamp > newFlowProgressStatus.timestamp
        ) {
          finalFlowProgressStatus = currentFlowProgressStatus;
        }
      }

      const { timestamp, ...productStatuses } = finalFlowProgressStatus;
      const productStatusEntries: [string, FlowProgress][] =
        Object.entries(productStatuses);

      const flowProgress = isEmpty(productStatusEntries)
        ? FlowProgress.Started
        : productStatusEntries[0][1];

      const isBindFlow =
        flowProgressSeqList.indexOf(flowProgress) >=
        flowProgressSeqList.indexOf(FlowProgress.App);

      const currentFlow = isBindFlow ? FlowType.Bind : FlowType.Quote;

      return {
        ...state,
        digitalsales: withKey(
          withKey(state.digitalsales, 'currentFlow', currentFlow),
          'currentFlowProgressStatus',
          finalFlowProgressStatus,
        ),
      };
    });
  }
};

const enableFlowPage = (
  state: GlobalState,
  flowType: FlowType,
  flow: Flow,
  route: string,
  indicator: boolean,
) => {
  let pageFound = false;

  Object.values(flow).forEach((module) => {
    module.pages.forEach((page) => {
      if (page.route === route && page.active) {
        page.enabled = indicator;
        finalizeModule(module);
        pageFound = true;
      }
    });
  });
  if (pageFound) {
    return {
      ...state,
      digitalsales: withKey(state.digitalsales, flowType, flow),
    };
  }
  return null;
};
export const enablePage = (
  ctx: GlobalStateContext,
  route: string,
  indicator: boolean,
): void =>
  ctx.dispatch((state) => {
    const quoteFlow = cloneDeep(state.digitalsales.quoteFlow);
    const bindFlow = cloneDeep(state.digitalsales.bindFlow);

    const result = enableFlowPage(
      state,
      FlowType.Quote,
      quoteFlow,
      route,
      indicator,
    );
    if (result) {
      return result;
    }

    return (
      enableFlowPage(state, FlowType.Bind, bindFlow, route, indicator) || state
    );
  });

export const registerPageEnabler = (
  ctx: GlobalStateContext,
  route: string,
  enabler: PageEnabler,
  productCode?: ProductCode,
): void =>
  ctx.dispatch((state) => {
    const pageEnablerMap = cloneDeep(state.digitalsales.pageEnablerMap);
    const pageEnablers = pageEnablerMap[route];

    if (pageEnablers) {
      const finalPageEnablers = uniqBy(
        [...pageEnablers, { productCode, canEnable: enabler }],
        'productCode',
      );
      pageEnablerMap[route] = finalPageEnablers;
    } else {
      pageEnablerMap[route] = [{ productCode, canEnable: enabler }];
    }

    return {
      ...state,
      digitalsales: withKey(
        state.digitalsales,
        'pageEnablerMap',
        pageEnablerMap,
      ),
    };
  });
export const getPageEnablerMap = (ctx: GlobalStateContext): PageEnablerMap =>
  ctx.readState((state) => state.digitalsales.pageEnablerMap);

export const getFeatureState = (
  features: FeatureEntry[] | undefined,
  featureManagement: FeatureManagement | undefined,
  enabledDefault: boolean,
) => {
  const {
    key = '',
    whenDisabled = false,
    targetVariationKey = '',
  } = featureManagement || {};
  const availableFeature = features?.find(
    (feature) => feature.featureKey === key,
  );
  const {
    enabled = enabledDefault,
    variationKey = '',
    variables = {},
  } = availableFeature || {};
  const isEnabled =
    enabled && (!targetVariationKey || targetVariationKey === variationKey);
  const featureActive =
    (isEnabled && !whenDisabled) || (!isEnabled && whenDisabled);
  return { enabled: featureActive, variables };
};

const setPageState = (
  userInfo: FeatureUserInfo,
  page: Page,
  features?: FeatureEntry[],
) => {
  const { enabled } = getFeatureState(features, page.featureManagement, true);
  const { productCodes } = userInfo;
  const active =
    enabled &&
    (isEmpty(page.productCodes) ||
      productCodes.some((productCode) =>
        page.productCodes?.includes(productCode),
      ));
  if (active) {
    if (!page.active) {
      page.active = true;
      page.enabled = !page.initDisabled;
    }
  } else if (page.active) {
    page.active = false;
    page.enabled = false;
  }
};

export const configureAppFeatures = (
  ctx: GlobalStateContext,
  newUserInfo: FeatureUserInfo,
  featureResourceKey: string,
): void => {
  const { userInfo } = ctx.readState((state) => state.digitalsales.featureMeta);

  if (!isEqual(newUserInfo, userInfo)) {
    const features = ctx.readState(
      (state) => state.digitalsales.resourceData[featureResourceKey].features,
    );

    ctx.dispatch((state) => {
      const quoteFlow = cloneDeep(state.digitalsales.quoteFlow);
      const bindFlow = cloneDeep(state.digitalsales.bindFlow);

      Object.values(quoteFlow).forEach((module) => {
        module.pages.forEach((page) => {
          setPageState(newUserInfo, page, features);
        });
        finalizeModule(module);
      });

      Object.values(bindFlow).forEach((module) => {
        module.pages.forEach((page) => {
          setPageState(newUserInfo, page, features);
        });
        finalizeModule(module);
      });

      const { productCodes } = newUserInfo;

      return {
        ...state,
        digitalsales: withKey(
          withKey(
            withKey(
              withKey(state.digitalsales, 'featureMeta', {
                userInfo,
                featureResourceKey,
              }),
              'productCodes',
              productCodes,
            ),
            FlowType.Quote,
            quoteFlow,
          ),
          FlowType.Bind,
          bindFlow,
        ),
      };
    });
  }
};
