/* eslint-disable jsx-a11y/anchor-is-valid */
import Link, { LinkProps } from '@mui/material/Link';
import type { ResourceConfig } from '../../app/types';

declare let CONFIG: ResourceConfig;
export interface ResourceLinkProps
  extends Omit<LinkProps, 'href' | 'role' | 'aria-disabled'> {
  hrefKey: string;
}
const ResourceLink: React.FC<React.PropsWithChildren<ResourceLinkProps>> = ({
  hrefKey,
  children,
  ...props
}) => {
  const hrefLink = CONFIG[hrefKey].baseURL;
  return (
    <>
      {hrefLink && (
        <Link {...props} href={hrefLink}>
          {children}
        </Link>
      )}
      {!hrefLink && (
        <Link {...props} role="link" aria-disabled="true">
          {children}
        </Link>
      )}
    </>
  );
};

export default ResourceLink;
