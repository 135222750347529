import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useProgressStepperFlowModules } from '../../../app/ContextAPI';
import { PageDisplay } from './types';

/**
 * Converts a string to a sentence case string aka the first letter is upper case and the rest is lower cased.
 * @param str string to convert
 * @returns a sentence cased string
 */
export const toSentenceCase = (str: string | undefined) => {
  const mappedString = str?.toLowerCase() || '';
  return mappedString?.length > 0
    ? mappedString!.charAt(0).toUpperCase() + mappedString.substring(1)
    : '';
};
/**
 * Gathers the mobile page name from the current module and location and returns the name
 * @returns An page name in the form { moduleName, pageName }
 */
export const useMobilePageName = (): PageDisplay => {
  const flowModules = useProgressStepperFlowModules();
  const location = useLocation();
  const activeModule = flowModules.findIndex((module) =>
    module.routes.includes(location.pathname),
  );
  return useMemo(() => {
    let activePage = { pageName: '', moduleName: '' };
    if (activeModule !== undefined && activeModule !== -1) {
      const page = flowModules[activeModule]?.pages?.find(
        (p) => p.route === location.pathname,
      );
      const moduleName = flowModules[activeModule]?.label;
      activePage = {
        moduleName,
        pageName: page?.label || '',
      };
    }
    return activePage;
  }, [activeModule, flowModules, location]);
};
